export default class Header {

	constructor(el) {

		this.DOM = { el: el };
		this.DOM.body = document.body;
		this.DOM.brand = this.DOM.el.querySelector('.brand');
		this.DOM.toggle = this.DOM.el.querySelector('.Toggle__menu');
		this.DOM.menuContainer = this.DOM.el.querySelector('Nav__container');
		this.menuOpen = false;

		this.init();

	}

	init() {

	// Toggle
	this.DOM.toggle.addEventListener('click', (e) => {

		e.preventDefault();
		this.menuOpen === true ? this.close() : this.open();

	});

}

open() {

	return new Promise((resolve, reject) => {


		this.menuOpen = true;
		this.menuAnimating = true;
		this.DOM.body.classList.add('showMenu');
		this.DOM.body.style.overflow = 'hidden';

		const openMenu = new TimelineMax({paused:true, onComplete:() => {
			this.menuAnimating = false;
		}})
		openMenu.fromTo(document.querySelector(".Nav__main"), 0.6, {y:"50%"}, {y:"0%", ease:Power3.easeInOut}, 0.2);
		openMenu.fromTo(document.querySelector(".Nav__container"), 1, {y:"100%"}, {y:"0%", ease:Power3.easeInOut}, 0);
		openMenu.play();
	});


}

close() {

	return new Promise((resolve, reject) => {

		this.menuOpen = false;
		//this.DOM.body.classList.remove('showMenu');
		this.DOM.body.style.overflow = '';
		const closeMenu = new TimelineMax({paused:true, onComplete:() => {
			this.menuAnimating = false;
			this.DOM.body.classList.remove('showMenu');
		}})

		closeMenu.fromTo(document.querySelector(".Nav__main"), 0.6, {y:"0%"}, {y:"-50%", ease:Power3.easeInOut}, 0.2);
		closeMenu.fromTo(document.querySelector(".Nav__container"), 1, {y:"0"}, {y:"-100%", ease:Power3.easeInOut}, 0.1);
		closeMenu.play();



	});

}

}
