import Highway from '@dogstudio/highway';
import {
    randomInt
} from '../utils.js';

export default class PageMyRessources extends Highway.Renderer {

    onEnter() {

        if (window.DEVMODE) console.log('Page Ressource');

        this.DOM = { view: this.wrap.lastElementChild };


        function get_favorite() {
            // GET FAVORITE
            var ajaxurl = $("#ressource").data("get_favories");
            $.ajax({
                type: "GET",
                url: ajaxurl,
                data: "",
                success: function(data) {
                    data = JSON.parse(data);
                    if (data.html == 0) {
                        $('#no-result').show();
                        $('#result').hide();
                    } else {
                        $('#result').html(data.html);
                    }
                },
                error: function(data) {}
            });
        }

        // remove FAVORITE
        // $("body").on("click", ".delete-offer__link", function(e) {
        //     e.preventDefault();
        //     var self = $(this);
        //     var ajaxurl = self.data("ajaxurl");
        //     var card = self.parents("li.job__card");
        //     // Back office
        //     $.ajax({
        //         type: "GET",
        //         url: ajaxurl,
        //         data: "",
        //         success: function(data) {
        //             data = JSON.parse(data);
        //             if (window.DEVMODE) console.log(data);
        //             if (data.status == "remove") {
        //                 if (window.DEVMODE) console.log("remove");
        //                 card.fadeOut();
        //                 if (data.nb == 0) {
        //                     $('#no-result').show();
        //                     $('#result').hide();
        //                 }
        //             } else {
        //                 if (window.DEVMODE) console.log("error");
        //             }
        //         },
        //         error: function(data) {
        //             if (window.DEVMODE) console.log(data);
        //         }
        //     });
        // });

        get_favorite();


    }

}
