import Highway from '@dogstudio/highway';
import { randomInt } from '../utils.js';

export default class PageHome extends Highway.Renderer {

  onEnter() {

    if (window.DEVMODE) console.log('PageHome');
    this.DOM = { view: this.wrap.lastElementChild };
    this.DOM.mosaik = this.DOM.view.querySelector('#Mosaik');
    this.DOM.mosaikImages = this.DOM.view.querySelectorAll('.mosaique__container .images img');
    this.mosaikImages = [];

    this.createMosaik(this.DOM.mosaik);
    this.loadImages();

    // Render
    TweenMax.ticker.addEventListener('tick', this.render, this, true, 1);

  }

  createMosaik(dom) {

    this.canvas = dom;
    this.ctx = this.canvas.getContext('2d');
    this.resizeCanvas();

  }

  loadImages() {
    this.DOM.mosaikImages.forEach((item) => {

      const mosaikImg = new Image();

      mosaikImg.onload = () => {
        mosaikImg._width = this.imageWidth;
        mosaikImg._ratio = mosaikImg.height / mosaikImg.width;
        mosaikImg._height = mosaikImg._width * mosaikImg._ratio;
        mosaikImg._vitesse = randomInt(5, 15) / 10;
        mosaikImg._randomX = randomInt(1, 100);
        mosaikImg._randomY = randomInt(1, 100);
        mosaikImg._y = Math.round(this.canvas.height + mosaikImg._randomY * this.canvas.height / 100);
        this.mosaikImages.push(mosaikImg);
      }

      mosaikImg.src = item.getAttribute('src');

    });
  }

  drawImage() {

    this.mosaikImages.forEach((img) => {

      img._y -= img._vitesse;

      if (img._y <= -img._height) {
        img._y = this.canvas.height + randomInt(1, 100);
        img._randomX = randomInt(1, 100);
      }

      this.ctx.drawImage(img, Math.round(img._randomX * this.canvas.width / 100), img._y, this.imageWidth, this.imageWidth * img._ratio);

    });

  }

  resizeCanvas() {
    const displayWidth = Math.floor(this.canvas.clientWidth);
    const displayHeight = Math.floor(this.canvas.clientHeight);

    // Check if the canvas is not the same size.
    if (this.canvas.width !== displayWidth || this.canvas.height !== displayHeight) {

      // Make the canvas the same size
      this.canvas.width = displayWidth;
      this.canvas.height = displayHeight;
      this.imageWidth = this.canvas.width / 10;
    }


  }

  render() {

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.resizeCanvas();
    this.drawImage();

  }

  onLeaveCompleted() {
    TweenMax.ticker.removeEventListener('tick', this.render, this, true, 1);
  }


}
