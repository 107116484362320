import Highway from '@dogstudio/highway';
import {
    getPosition
} from '../utils.js';

export default class singleRessourceRenderer extends Highway.Renderer {

    // this.slideshowOpen = false;

    onEnter() {


        this.DOM = {
            view: this.wrap.lastElementChild
        };
        const btnOpenSlideshow = this.DOM.view.querySelector('.open--slideshow');
        const btnCloseSlideshow = this.DOM.view.querySelector('.close--slideshow');
        const visualContainer = this.DOM.view.querySelector('.Page__header--single-ressource .visual__container');
        this.DOM.visualContainer = visualContainer;
        this.DOM.mapContainer = this.DOM.view.querySelector('.map__section');
        const myBody = document.querySelector('body');

        this.addEvents();
        TweenMax.ticker.addEventListener('tick', this.visualFixed, this, true, 1);

        // Open slideshow 🖼❇️
        btnOpenSlideshow.addEventListener('click', (e) => {
            e.preventDefault();
            myBody.classList.add('open-popup-single-ressource');
            visualContainer.classList.add('open');
            const openSlideshow = new TimelineMax({
                paused: true,
                onComplete: () => {
                    // mySwiper.init();
                }
            })

            openSlideshow.to(window, 1.2, {
                scrollTo: {
                    y: visualContainer.offsetTop,
                    autoKill: false
                },
                ease: Power3.easeInOut
            });
            openSlideshow.to(visualContainer, 1, {
                width: '100%',
                ease: Power3.easeInOut
            }, 0);
            openSlideshow.play();

        });

        // Close slideshow 🖼❌
        btnCloseSlideshow.addEventListener('click', (e) => {
            e.preventDefault();
            myBody.classList.remove('open-popup-single-ressource');
            visualContainer.classList.remove('open');
            const closeSlideshow = new TimelineMax({
                paused: true,
                onComplete: () => {}
            })

            closeSlideshow.to(visualContainer, 1, {
                width: "40%",
                ease: Power3.easeInOut
            }, 0);
            closeSlideshow.play();
        });

        const mySwiper = new Swiper('.swiper-container', {
            // Optional parameters
            loop: true,
            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            }
        });

        var ajaxurl = $("#map_single").data("ajaxurl");
        var map_coordinate_single;
        $.ajax({
            url: ajaxurl,
            type: "POST",
            data: {},
            success: function(data) {
                data = JSON.parse(data);
                map_coordinate_single = data;
                init_map();
            },
            error: function(data) {
            }
        })

        let map_single = null;
        function init_map() {
            map_single = L.map('map_single', { dragging: false, zoomControl: false }).setView([map_coordinate_single.lat, map_coordinate_single.lng], 15);
            L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                minZoom: 15,
                maxZoom: 15
            }).addTo(map_single);
            let marker = L.marker([map_coordinate_single.lat, map_coordinate_single.lng]).addTo(map_single);
        }

        // ---------

        function check_favorite() {
            // CHECK FAVORITE
            var ajaxurl = $("#toggle-favorite").data("favorite");
            $.ajax({
                type: "GET",
                url: ajaxurl,
                data: "",
                success: function(data) {
                    data = JSON.parse(data);
                    if (window.DEVMODE) console.log("favorite : " + data.check);
                    if (data.check) {
                        $('#toggle-favorite').attr("checked", true);
                    }
                },
                error: function(data) {}
            });
        }

        // ADD FAVORITE
        $("#toggle-favorite").on("click", function(e) {
            // e.preventDefault();
            var self = $(this);
            var ajaxurl = self.data("ajaxurl");
            // Back office
            $.ajax({
                type: "GET",
                url: ajaxurl,
                data: "",
                success: function(data) {
                    $('#toggle-favorite').attr("checked", data.check);
                    if (window.DEVMODE) console.log(data.status);
                },
                error: function(data) {
                    if (window.DEVMODE) console.log(data);
                }
            });
        });

        check_favorite()
    }

    addEvents() {
        this.sy = 0;
        window.addEventListener('scroll', () => {
            this.sy = window.pageYOffset || document.documentElement.scrollTop;
        });
    }

    visualFixed() {

        if (window.innerWidth < 992) return;

        const end = Math.round(getPosition(this.DOM.mapContainer).y);
        const isFixed = end >= window.innerHeight;

        if (isFixed) {
            TweenLite.set(this.DOM.visualContainer, {
                y: this.sy,
                ease: Power0
            });
        }
    }

    onLeaveCompleted() {
        TweenMax.ticker.removeEventListener('tick', this.visualFixed);
    }

}
