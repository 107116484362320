import Highway from '@dogstudio/highway';
export default class singleEventRenderer extends Highway.Renderer {

    onEnter() {

        this.DOM = {
            mapInteractive: document.querySelector('#map')
        }

        // map
        var map_init = false;
        var list = new Array();
        var img_marker = themeUrl + "/dist/imgs/icons/marker.png";
        var ressources = [];
        var lat = 50.433128;
        var lon = 2.82797;
        var map = null;
        var map_showed = false;
        var markerClusters;
        var markers = [];
        var tile;
        var regionData;

        this.init();
        console.log('New');
        return ;

        $.getJSON(themeUrl + "/dist/data/dataRegion.json", function (data) {
            regionData = data;
        });

        function style(feature) {
            var color =
                feature.properties.nom == "Hauts-de-France"
                    ? "#E8E6E2"
                    : // nom == "Hauts-de-France"   ? '#FD8D3C' :
                    "#FFEDA0";

            var fillOpacity =
                feature.properties.nom == "Hauts-de-France" ? 0.4 : 0;

            return {
                fillColor: "#005D6D",
                weight: 2,
                opacity: 1,
                color: "white",
                dashArray: "3",
                fillOpacity: "0.12",
            };
        }

        function init_map() {
            console.log("init_map");

            markerClusters = L.markerClusterGroup();
            if (map == null) {
                map = L.map("map", { scrollWheelZoom: false }).setView(
                    [lat, lon],
                    8
                );

                // PROD
                // tile = L.tileLayer("https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",{
                //         attribution:
                //             '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
                //         minZoom: 8,
                //         maxZoom: 16,
                //     }).addTo(map);

                tile = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
                  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                  subdomains: 'abcd',
                  maxZoom: 19
                }).addTo(map);



                L.geoJson(regionData, { style: style }).addTo(map);


            }

            if (ressources == undefined) {
                console.log("map_undefined");
            }else {
              for (var ressource in ressources) {
                if (ressources[ressource].coordinate_osm.lat) {
                  var myIcon = L.icon({
                    iconUrl: themeUrl + "/dist/imgs/icons/marker.png",
                    iconSize: [30, 40],
                    iconAnchor: [20, 30],
                    popupAnchor: [-5, -6],
                  });
                  var marker = L.marker(
                    [
                      ressources[ressource].coordinate_osm.lat,
                      ressources[ressource].coordinate_osm.lng,
                    ],
                    {
                      title: ressources[ressource].id,
                      icon: myIcon,
                    }
                  );
                  markers.push(marker);

                  // POPUP
                  marker.bindPopup(
                    "<h3>Ville d'art & d'histoire Tourcoing</h3>" +
                    "<p>Maison Folie Hospice d'Havré <br>100, rue de Tournai</p>" +
                    "<div class='mailto'><a href='mailto:labelvah@ville-tourcoing.fr'>labelvah@ville-tourcoing.fr</a></div>" +
                    "<div class='phone'><a href='tel:0359634340'>0359634340</a></div>" +
                    "<a href='' class='btn' target='_blank'>Découvrez la programmation</a>"
                  );
                  markerClusters.addLayer(marker);
                }
              }
              map.addLayer(markerClusters);
            }


            var id_preselect = $("#map").data("preselect");
            if (id_preselect) {
                markerFocus(id_preselect);
            }
            $("body").css("cursor", "default");
        }

        function markerFocus(id_preselect) {
            for (var i in markers) {
                if (markers[i].options.title == id_preselect) {
                    map.panTo([markers[i]._latlng.lat, markers[i]._latlng.lng]);
                    var m = markers[i];
                    markerClusters.zoomToShowLayer(m, function () {
                        m.openPopup();
                    });

                    $("#toggle-carte").trigger("click");
                }
            }
        }

        var ajaxurl = $("#map").data("ajaxurl");
              $.ajax({
                url: ajaxurl,
                type: "POST",
                data: {},
                success: (data)=> {
                  data = JSON.parse(data);
                  ressources = data;
                  init_map();
                },
                error: function (data) {
                  console.log(data);
                },
              });

        init_map()
    }

    init() {
        const { mapInteractive } = this.DOM
        if (mapInteractive) this.initMapInteractive();
    }


    initMapInteractive() {


        var map_init = false;
        var list = new Array();
        var img_marker = themeUrl + "/dist/imgs/icons/marker.png";
        var ressources = [];
        var lat = 50.433128;
        var lon = 2.82797;
        var map = null;
        var map_showed = false;
        var markerClusters;
        var markers = [];
        var tile;
        var regionData;

    const { mapInteractive } = this.DOM;
    const mapOptions = {
        scrollWheelZoom: false,
        center: [50.433128, 2.82797],
        zoom: 8,
        zoomControl: false,
        //layers: siteLayers,
        maxZoom: 50
    }

    this.mapInteractive = new L.map(mapInteractive, mapOptions);

    infojson.map.sites.forEach((site) => {
        console.log(site.coordinates);
        var myIcon = L.icon({
            iconUrl: themeUrl + "/dist/imgs/icons/marker.png",
            iconSize: [30, 40],
            iconAnchor: [20, 30],
            popupAnchor: [-5, -6],
        });


        const marker = L.marker(
            [site.coordinates.lat, site.coordinates.lng],
            { icon: myIcon }
        );
        var pop = "";
        if (site.title)
            pop = "<h3>" + site.title + "</h3>";
        if (site.address)
            pop += "<p>" + site.address + "</p>";
        if (site.email)
            pop += "<div class='mailto'><a href='mailto:" + site.email + "'>" + site.email + "</a></div>";
        if (site.phone)
            pop += "<div class='phone'><a href='tel:" + site.phone + "'>" + site.phone + "</a></div>";
        if (site.link)
            pop += "<a href='"+site.link+"' class='btn' target='_blank'>Découvrez la programmation</a>";

        marker.bindPopup(
            pop
        );
        marker.addTo(this.mapInteractive);
    });

    const layer = new L.TileLayer('https://api.mapbox.com/styles/v1/wokine/clepu41pk00fe01lkuk3ec55s/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1Ijoid29raW5lIiwiYSI6ImNqbzdhdXhuZTBmeXAzcnBnZnE4bWVzMWMifQ.gRXN2yYTM-SOpyZiWk9Frg')
    this.mapInteractive.addLayer(layer)
    setTimeout(() => {
        console.log('add controls')
        L.control.zoom({ position: 'bottomright' }).addTo(this.mapInteractive)
    }, 3000)
}

    onLeaveCompleted() {
    }

}
