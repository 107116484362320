import Highway from "@dogstudio/highway";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default class ressourcesRenderer extends Highway.Renderer {
    onEnter() {
        // elements
        var formFilters = $("#formFilters");
        var selectFilters = $(".selectFilters");
        var inputFilter = $("#inputFilter");
        const body = document.querySelector("body");
        const menuToggle = document.querySelector(".menu__toggle");
        const filtersContainer = document.querySelector(".filters");
        const filtersHeadline = document.querySelector(".search-intro h2");

        // Animation for Timelines
        this.timelineScrollTrigger;

        //const Msnrys;
        window.Msnrys = [];

        let menuOpen = false;

        // map
        var map_init = false;
        var list = new Array();
        var img_marker = themeUrl + "/dist/imgs/icons/marker.png";
        var ressources = [];
        var lat = 50.433128;
        var lon = 2.82797;
        var map = null;
        var map_showed = false;
        var markerClusters;
        var markers = [];
        var tile;
        var regionData;

        $.getJSON(themeUrl + "/dist/data/dataRegion.json", function (data) {
            regionData = data;
        });

        // filters
        if (
            window.global_filter.tags.length > 0 ||
            window.global_filter.keyword.length > 0
        ) {
            applyFilters();
        }

        window.global_filter.section_displayed = "ressource";
        var timerInput;

        $("#toggle-liste").on("click", function () {
            document.getElementById("content_list").style.display = "block";
            document.getElementById("content_frise").style.display = "none";
            document.getElementById("content_map").style.display = "none";

            document.getElementById("ressource_list").style.display = "block";
            document.getElementById("ressource_map").style.display = "none";
            document.getElementById("ressource_timeline").style.display = "none";

            // Show filters bar
            document.querySelector(".filters-bar").style.display = "block";

            window.global_filter.section_displayed = "ressource";
        });
        $("#toggle-carte").on("click", function () {
            document.getElementById("content_map").style.display = "block";
            document.getElementById("content_list").style.display = "none";
            document.getElementById("content_frise").style.display = "none";

            document.getElementById("ressource_list").style.display = "none";
            document.getElementById("ressource_map").style.display = "block";
            document.getElementById("ressource_timeline").style.display = "none";

            // Hide filters bar
            document.querySelector(".filters-bar").style.display = "none";

            window.global_filter.section_displayed = "map";
            map_showed = true;
            console.log(map_showed);
            applyFilters();
        });
        $("#toggle-frise").on("click", function () {
            document.getElementById("content_frise").style.display = "block";
            document.getElementById("content_list").style.display = "none";
            document.getElementById("content_map").style.display = "none";

            document.getElementById("ressource_list").style.display = "none";
            document.getElementById("ressource_map").style.display = "none";
            document.getElementById("ressource_timeline").style.display = "block";

            // Hide filters bar
            document.querySelector(".filters-bar").style.display = "none";

            window.global_filter.section_displayed = "timeline";

            gsap.utils.toArray(".era").forEach(section => {
                this.timelineScrollTrigger = ScrollTrigger.create({
                    trigger: section,
                    pin: section.querySelector(".era-title"),
                    start: "top top",
                    end: (self) => `+=${section.offsetHeight - self.pin.offsetHeight}`,
                    pinSpacing: false,
                    // markers: false
                });
            });
        });

        var url = new URL(window.location.href);
        var tab = url.searchParams.get("tab");
        if (tab == "map") {
            $("#toggle-carte").trigger("click");
        }

        formFilters.submit(function (e) {
            e.preventDefault();
            applyFilters();
        });

        selectFilters.change(function (e) {
            var value = $(this).val();
            var taxo = $(this).data("taxo");

            if (window.global_filter.tags.indexOf(value) === -1) {
                window.global_filter.tags.push(value);
                if (taxo == "territoire")
                    window.global_filter.territoire.push(value);
                if (taxo == "periode") window.global_filter.periode.push(value);
                if (taxo == "theme") window.global_filter.theme.push(value);
            }
            selectFilters.find("option.defaultOption").prop("selected", true);
            applyFilters();
        });

        timerInput = false;
        inputFilter.keyup(function () {
            clearTimeout(timerInput);
            selectFilters.find("option#defaultOption").prop("selected", true);
            timerInput = setTimeout(function () {
                window.global_filter.keyword = inputFilter.val();
                applyFilters();
            }, 500);
        });

        function showBlockTaxo() {
            $(".block_preview").hide();
            if (window.global_filter.tags.length == 1) {
                $(
                    '.block_taxo[data-value="' +
                        window.global_filter.tags[0] +
                        '"]'
                ).fadeIn();
            }
        }

        // Filters
        function applyFilters() {
          console.log("applyFilters");
            $("body").css("cursor", "wait");
            var ajaxurl = formFilters.data("ajaxurl");

            showBlockTaxo();
            $.ajax({
                url: ajaxurl,
                type: "POST",
                data: {
                    territoire: window.global_filter.territoire,
                    periode: window.global_filter.periode,
                    theme: window.global_filter.theme,
                    s: window.global_filter.keyword,
                    section_displayed: window.global_filter.section_displayed,
                },
                //success: function(data) {
                success: (data) => {
                    data = JSON.parse(data);
                    filter_ressource(data);
                },
                error: function () {
                    $("body").css("cursor", "default");
                },
            });

            console.log(map_showed);
            if (map_showed == true) {
              var ajaxurl = $("#map").data("ajaxurl");
              $.ajax({
                url: ajaxurl,
                type: "POST",
                data: {},
                success: (data)=> {
                  data = JSON.parse(data);
                  ressources = data;
                  init_map();
                },
                error: function (data) {
                  console.log(data);
                },
              });
            }
        }



        function style(feature) {
            var color =
                feature.properties.nom == "Hauts-de-France"
                    ? "#E8E6E2"
                    : // nom == "Hauts-de-France"   ? '#FD8D3C' :
                    "#FFEDA0";

            var fillOpacity =
                feature.properties.nom == "Hauts-de-France" ? 0.4 : 0;

            return {
                fillColor: "#005D6D",
                weight: 2,
                opacity: 1,
                color: "white",
                dashArray: "3",
                fillOpacity: "0.12",
            };
        }

        function init_map() {
            console.log("init_map");

            markerClusters = L.markerClusterGroup();
            if (map == null) {
                map = L.map("map", { scrollWheelZoom: false }).setView(
                    [lat, lon],
                    8
                );

                // PROD
                // tile = L.tileLayer("https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png",{
                //         attribution:
                //             '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
                //         minZoom: 8,
                //         maxZoom: 16,
                //     }).addTo(map);

                tile = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
                  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
                  subdomains: 'abcd',
                  maxZoom: 19
                }).addTo(map);



                L.geoJson(regionData, { style: style }).addTo(map);

                if (window.global_filter.section_displayed != "map") {
                    document.getElementById("ressource_map").style.display =
                        "none";
                }
            }

            if (ressources == undefined) {
                console.log("map_undefined");
            }else {
              for (var ressource in ressources) {
                if (ressources[ressource].coordinate_osm.lat) {
                  var myIcon = L.icon({
                    iconUrl: img_marker,
                    iconSize: [30, 40],
                    iconAnchor: [20, 30],
                    popupAnchor: [-5, -6],
                  });
                  var marker = L.marker(
                    [
                      ressources[ressource].coordinate_osm.lat,
                      ressources[ressource].coordinate_osm.lng,
                    ],
                    {
                      title: ressources[ressource].id,
                      icon: myIcon,
                    }
                  );
                  markers.push(marker);

                  // POPUP
                  marker.bindPopup(
                    "<a href='" +
                    ressources[ressource].url +
                    "'>" +
                    "<div class='visual-container'><div class='visual' style='background-image: url(" +
                    ressources[ressource].visuel +
                    ")'></div></div>" +
                    "<div class='ressource-card__label__wrapper'><p class='ressource-card__label'>" +
                    ressources[ressource].selection +
                    "</p></div>" +
                    "<h3>" +
                    ressources[ressource].title +
                    "</h3>" +
                    "<p class='marker-link'>En savoir plus</p>" +
                    "</a>"
                  );
                  markerClusters.addLayer(marker);
                }
              }
              map.addLayer(markerClusters);
            }


            var id_preselect = $("#map").data("preselect");
            if (id_preselect) {
                markerFocus(id_preselect);
            }
            $("body").css("cursor", "default");
        }

        function markerFocus(id_preselect) {
            for (var i in markers) {
                if (markers[i].options.title == id_preselect) {
                    map.panTo([markers[i]._latlng.lat, markers[i]._latlng.lng]);
                    var m = markers[i];
                    markerClusters.zoomToShowLayer(m, function () {
                        m.openPopup();
                    });

                    $("#toggle-carte").trigger("click");
                }
            }
        }

        function filter_ressource(data) {
            $("body").css("cursor", "default");

            ressources = data.html;
            if (
                window.global_filter.section_displayed == "timeline" &&
                data.nb != "0"
            ) {
                for (var ressource in ressources) {
                    var periode_section = "timeline_" + ressource;
                    $("#" + periode_section).html(ressources[ressource]);
                }
            } else {
                $(".ressources_list_filter_result").html(data.html.all);
                window.H.attach($(".ressources_list_filter_result a"));
                $("#nb_results").text(data.nb);

                setTimeout(() => {
                    document
                        .querySelectorAll(".ressources_list_filter_result")
                        .forEach((item) => {
                            const msnry = new Masonry(item, {
                                itemSelector: ".grid-item",
                                percentPosition: true,
                            });

                            window.Msnrys.push(msnry);
                        });
                }, 500);
            }

            if (
                window.global_filter.tags.length > 0 ||
                window.global_filter.keyword !== ""
            ) {
                $(".default_list").fadeOut();
                $(".result_list").fadeIn();

                $(".tag").remove();

                if (window.global_filter.tags) {
                    window.global_filter.tags.forEach((tag) => {
                        const tagElem = document.createElement("span");
                        tagElem.className = "tag";
                        // tagElem.innerHTML = `<span>${tag}</span><a href="#" class="tag__remove"><span>supprimer</span></a>`;
                        tagElem.innerHTML = `<span>${tag}</span><a href="#" class="tag__remove"><span>supprimer</span></a>`;
                        tagElem.tag = tag;

                        tagElem
                            .querySelector(".tag__remove")
                            .addEventListener("click", removeTag);
                        filtersHeadline.appendChild(tagElem);
                    });
                }
                var bubble = document.getElementById("searchFilter");
                if (window.global_filter.keyword !== "") {
                    if (!bubble) {
                        const tagElem = document.createElement("span");
                        tagElem.className = "tag";
                        // tagElem.innerHTML = `<span id="searchFilter">${window.global_filter.keyword}</span><a href="#" class="tag__remove"><span>supprimer</span></a>`;
                        tagElem.innerHTML = `<span id="searchFilter">${window.global_filter.keyword}<a href="#" class="tag__remove"><span>supprimer</span></a></span>`;
                        tagElem.tag = window.global_filter.keyword;
                        tagElem
                            .querySelector(".tag__remove")
                            .addEventListener("click", removeSearch);
                        filtersHeadline.appendChild(tagElem);
                    } else {
                        bubble.innerText = window.global_filter.keyword;
                    }
                } else {
                    if (bubble) bubble.remove();
                }
                return;
            } else {
                $(".default_list").fadeIn();
                $(".result_list").fadeOut();
                return;
            }
        }

        function removeTag(e) {
            e.preventDefault();

            //Delete DOM Tag
            let item = e.currentTarget.parentNode;
            item.parentNode.removeChild(item);
            //Delete in Tags[]
            var tagIndex = window.global_filter.tags.indexOf(item.tag);
            window.global_filter.tags.splice(tagIndex, 1);

            var tagIndexTerritoire = window.global_filter.territoire.indexOf(
                item.tag
            );
            if (tagIndexTerritoire !== -1)
                window.global_filter.territoire.splice(tagIndexTerritoire, 1);
            var tagIndexPeriode = window.global_filter.periode.indexOf(
                item.tag
            );
            if (tagIndexPeriode !== -1)
                window.global_filter.periode.splice(tagIndexPeriode, 1);
            var tagIndexTheme = window.global_filter.theme.indexOf(item.tag);
            if (tagIndexTheme !== -1)
                window.global_filter.theme.splice(tagIndexTheme, 1);

            console.log("Tag Deleted", window.global_filter.tags);

            applyFilters();
        }

        function removeSearch(e) {
            e.preventDefault();

            //Delete DOM Tag
            let item = e.currentTarget.parentNode;
            item.parentNode.removeChild(item);

            //Delete in Tags[]
            window.global_filter.keyword = "";
            inputFilter.val("");

            console.log("Tag Deleted", window.global_filter.tags);

            applyFilters();
        }

        function restoreTags(tagsarray) {
            var options = $("#selectFilters option");
            tagsarray.forEach(function (tag) {
                options.each(function () {
                    var option_value = $(this).val().toLowerCase();
                    if (option_value === tag) {
                        window.global_filter.tags.push($(this).val());
                    }
                });
            });
            applyFilters();
        }

        // Preselect thematique

        setTimeout(function () {
            var theme_id = url.searchParams.get("themes");
            if (theme_id != null) {
                var theme_id = decodeURIComponent(theme_id);
                var ajaxurl = $("#map").data("get_value_theme_ajaxurl");

                $.ajax({
                    url: ajaxurl,
                    type: "POST",
                    data: {
                        theme_id: theme_id,
                    },
                    success: (data) => {
                        $("body").css("cursor", "default");
                        data = JSON.parse(data);
                        console.log(data);
                        window.global_filter.tags.push(data.value);
                        window.global_filter.theme.push(data.value);
                        selectFilters
                            .find("option.defaultOption")
                            .prop("selected", true);
                        applyFilters();
                    },
                    error: function () {
                        $("body").css("cursor", "default");
                    },
                });
            }
        }, 2000);

        // PRESELECTION TYPE D EVENT
        function $_GET(param) {
            var vars = {};
            window.location.href.replace(location.hash, "").replace(
                /[?&]+([^=&]+)=?([^&]*)?/gi, // regexp
                function (m, key, value) {
                    // callback
                    vars[key] = value !== undefined ? value : "";
                }
            );
            if (param) {
                return vars[param] ? vars[param] : null;
            }
            return vars;
        }

        var mots_cles = $_GET("mots_cles");
        if (mots_cles) {
            mots_cles = mots_cles.replace(/-/g, " ");
            inputFilter.val(mots_cles);
            window.global_filter.keyword = mots_cles;

            setTimeout(function () {
                applyFilters();
            }, 600);
        }
    }

    // animationTimelineScroll() {
    //     // Animation for Timeline
    //     console.log("🔍 Animation for Timeline");

    //     gsap.utils.toArray(".era").forEach(section => {
    //         this.timelineScrollTrigger = ScrollTrigger.create({
    //             trigger: section,
    //             pin: section.querySelector(".era-title"),
    //             start: "top 50px",
    //             end: (self) => "+=" + (section.offsetHeight - self.pin.offsetHeight),
    //             pinSpacing: false,
    //         });
    //     });
    // }

    onEnterCompleted() {
        var elem = document.querySelectorAll(".row-masonry.grid");

        elem.forEach((item) => {
            const msnry = new Masonry(item, {
                // options
                itemSelector: ".grid-item",
                percentPosition: true,
            });

            window.Msnrys.push(msnry);
        });
    }
}
