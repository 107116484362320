// Highway
import Highway from '@dogstudio/highway';
import DefaultPageRenderer from '../pages/page-renderer.js';
import PageHome from '../pages/page-home.js';
import myRessources from '../pages/page-my-ressource.js';
import ressourcesRenderer from '../pages/page-ressources.js';
import singleRessourceRenderer from '../pages/page-single-ressource.js';
import singleEventRenderer from '../pages/page-single-event.js';
import DefaultPageTransition from '../pages/page-transition.js';

import pagePrllx from './prllx.js';
import pageInView from './inview.js';
import pageAnchors from './anchors.js';

export default class Routes {

	constructor(Header) {

		this.view = {};
		this.header = Header;
		this.navLinks = this.header.DOM.el.querySelectorAll('.Nav__link');

		this.createHighway();
		this.initView(document.body);

		// add class header for items color
		document.querySelector("header").classList.add(document.querySelector("[data-header]").dataset.header);

	}


	createHighway() {

		window.H = new Highway.Core({
			renderers: {
				pageHome: PageHome,
				pageMyRessources: myRessources,
				pagename: DefaultPageRenderer,
				singleRessource: singleRessourceRenderer,
				singleEvent: singleEventRenderer,
				pageRessources: ressourcesRenderer,
			},
			transitions: {
				default: DefaultPageTransition
			}
		});

		window.H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {


	  // RemovePrllx
	  this.view.prllx.destroy();

	});

		window.H.on('NAVIGATE_IN', ({ to, trigger, location }) => {


			document.querySelector("header").classList.remove("black");
			document.querySelector("header").classList.remove("white");
			document.querySelector("header").classList.remove("single-ressource");
			document.querySelector("header").classList.add(to.view.dataset.header);



	  // Menu active links
	  this.navLinks.forEach((link) => {
	  	link.classList.remove('is-active');
	  	if (link.href === location.href) {
	  		link.classList.add('is-active');
	  	}
	  });

	  this.initView(to.view);

	});

		window.H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {

	  // Analytics
	  /* if (typeof gtag !== 'undefined') {
		 gtag('config', 'UA-XXXXXXXXX-X', {
		   'page_path': location.pathname,
		   'page_title': to.page.title,
		   'page_location': location.href
		 });
		} */

	});

	}

	initView(container) {

		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;

	// Menu Toggle
	if (this.header.menuOpen) {
		const click = new Event('click');
		this.header.DOM.toggle.dispatchEvent(click);
	}

	// Check Anchors
	if (container.querySelector('.anchors__nav')) {
		this.view.anchors = new pageAnchors(container.querySelector('.anchors__nav'));
	}

	// Prllx / InView
	this.view.prllx = new pagePrllx(container);
	this.view.inview = new pageInView(container);

}

}
